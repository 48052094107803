function hideDropdownToggle() {
  var navbarToggle = document.getElementById("navbarDropdownToggle");
  if (navbarToggle) {
    navbarToggle.classList.remove("show");
  }
  var navbar = document.getElementById("navbar");
  if (navbar) {
    navbar.classList.remove("show");
  }
  var navbar2 = document.getElementById("navbar2");
  if (navbar2) {
    navbar2.classList.remove("show");
  }
}

// Mobile view: Toggle visibility of dropdown menus when clicking on navbar
var navbar = document.getElementById("navbar");
if (navbar) {
  navbar.addEventListener("click", function (event) {
    var target = event.target;
    if (target.classList.contains("dropdown-toggle")) {
      var dropdownMenu = target.nextElementSibling;
      if (dropdownMenu) {
        dropdownMenu.classList.toggle("show");
      }
    }
  });
}

// Desktop view: Hide dropdown menus when clicking outside the navbar
var navbarToggle = document.getElementById("navbarDropdownToggle");
if (navbarToggle) {
  document.addEventListener("click", function (event) {
    var target = event.target;
    if (!navbar.contains(target) && !navbarToggle.contains(target)) {
      var dropdownMenus = document.getElementsByClassName("dropdown-menu");
      for (var i = 0; i < dropdownMenus.length; i++) {
        dropdownMenus[i].classList.remove("show");
      }
    }
  });
}
